// *******~ Import ~******** //
// React
// Assets
import { lazy, createContext, useState, useContext } from "react";
import {useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// Components
import GoTop from "./common/gototop/gototop";
import ScrollToTop from "./common/scrolltop/scrolltop";
import Routing from "./router/router";
import Header from "./common/header/header";

import FeedBack from "./common/feedBack/feedBack";
// import Footer from "./common/footer/footer";
import { useEffect } from "react";
import { OrderFlow } from "./App";
const Footer = lazy(() => import("./common/footer/footer"));
// CSS
// Images
// Icons
// *******~ Import ~******** //

const Main = (params) => {
  const { footerLoading, setFooterLoading } = OrderFlow();
  const location = useLocation();
  const menuFooter = !location.pathname.includes("/menu")||!location.pathname.includes("/checkout");

  return (
    <>
      <GoTop />
      <ScrollToTop />
      <Header />
      <Routing stripe={params.stripe} />
      {menuFooter && <Footer />}
      {/* {footerLoading ? <Footer /> : null} */}
      {/* <FeedBack /> */}
    </>
  );
};
export default Main;
