//profileorder
// *******~ Import ~******** //
// React
import React, { useState, useContext, useEffect } from "react";
// Assets
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, MenuItem, InputAdornment, IconButton, Typography } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
// Components
import BackBtn from "../backbtn";
import "./profileV2.scss";
// CSS
import "../profile/savemodal.scss";
import "../profile/profile.scss";
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
// Images
import CouponImg from "../profile/img/balloons.svg";

// Icons
// *******~ Import ~******** //

// Redux wallet history
import { connect } from "react-redux";
import { getProfile } from "../../../../actions/myaccount/profile/getProfileActions";
import { saveProfile } from "../../../../actions/myaccount/profile/saveProfileActions";
import { sendProfileOtp } from "../../../../actions/myaccount/profile/sendProfileOtpActions";
import { Player } from "@lottiefiles/react-lottie-player";
import KeyLottie from "./img/key.json";
import SuccessLottie from "./img/SuccessLottie.json";
import FalidLottie from "./img/failed.json";
import "../profile/otp-screen.scss";
import { FormControl } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useRef } from 'react';
const Profile = ({
  profile,
  error,
  userData,
  getProfile,
  saveProfile,
  sendProfileOtp,
  sendotpprofile,
  saveprofile,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  if (location.pathname.includes("/profileV2") && width > breakpoint) {
    navigate("/myaccount");
  }
  // location.pathname.includes("/profile") && width > breakpoint
  //   ? navigate("/myaccount")
  //   :
  const [userotp, setUserotp] = useState("");
  const [successshow, setSuccessshow] = useState(false);
  const [failureshow, setFailureshow] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const profiledatas = profile?.data?.profile;
  const initialstate = {
    customer_id: userData.customerId,
    first_name: profiledatas?.fname ? profiledatas.fname : "",
    last_name: profiledatas?.lname ? profiledatas.lname : "",
    email: profiledatas?.email ? profiledatas.email : "",
    phone: profiledatas?.phone ? profiledatas.phone : "",

  };
  const [addData, setAddData] = useState(initialstate);
  const [profilesuccess, setProfilesuccess] = useState(false);
  //validation
  const [fNameError, setFNameError] = useState(false);
  const [lNameError, setLNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [numberValidationErrorMsg, setNumberValidationErrorMsg] = useState("");
  const [numValidationPhone, setNumValidationPhone] = useState("");
  //change ables
  const [isEditableFName, setIsEditableFName] = useState(false);
  const [isEditableLName, setIsEditableLName] = useState(false);
  const [isEditablePhone, setIsEditablePhone] = useState(false);

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
  ];
  const { theme } = useContext(ThemeContext);
  const inputRefFirstName = useRef(null);
  const inputRefLastname = useRef(null);
  const inputRefPhone = useRef(null);
  const fontChnage = {
    style: { color: "red" }
  };
  const fontGreen = {
    style: { color: "green" }
  };

  const handleChangeClick = (field) => {
    if (field === "firstname") {
      setIsEditableFName(true);
      setTimeout(() => {
        inputRefFirstName.current.focus();
      }, 0);
    }
    if (field === "lastname") {
      setIsEditableLName(true);
      setTimeout(() => {
        inputRefLastname.current.focus();
      }, 0);
    }
    if (field === "phone") {
      setIsEditablePhone(true);
      setTimeout(() => {
        inputRefPhone.current.focus();
      }, 0);
    }
  };

  useEffect(() => {
    if (userData) {
      getProfile({ customer_id: userData.customerId, body: "Your body" });
    }
  }, [userData, getProfile]);
  useEffect(() => {
    if (profiledatas) {
      const dataProfile = {
        customer_id: userData.customerId,
        first_name: profiledatas?.fname ? profiledatas.fname : "",
        last_name: profiledatas?.lname ? profiledatas.lname : "",
        email: profiledatas?.email ? profiledatas.email : "",
        phone: profiledatas?.phone ? profiledatas.phone : "",

      };
      setAddData(dataProfile);
    }
  }, [profiledatas])

  const submitotp = (otp) => {
    let newotp = otp?.toString()?.replace(/,/g, "");
    if (userotp == newotp) {
      // console.log(addData);
      saveProfile(addData);
    } else {
      if (newotp.length > 3) {
        setFailureshow(true);
      }
    }
  };

  useEffect(() => {
    if (sendotpprofile && sendotpprofile.status) {
      setUserotp(sendotpprofile.data);
    }
  }, [sendotpprofile]);

  useEffect(() => {
    if (saveprofile && saveprofile.status) {
      if (profiledatas.phone != addData.phone) {
        setSuccessshow(true);
      } else {
        setProfilesuccess(true);
      }
      getProfile({ customer_id: userData.customerId, body: "Your body" });
    }
  }, [saveprofile]);

  useEffect(() => {
    if (addData.first_name !== "" || addData.first_name !== null) {
      setFNameError(false);
    }
    if (addData.last_name !== "" || addData.last_name !== null) {
      setLNameError(false);
    }
    if (addData.phone !== "" || addData.phone !== null) {
      setPhoneError(false);
    }
    if (addData.email !== "" || addData.email !== null) {
      setFNameError(false);
    }

  }, [addData]);

  const handleInputAddChange = (field, value) => {
    console.log(value);
    let processedValue = value;

    console.log(processedValue);
    setAddData((prevData) => ({
      ...prevData,
      [field]: processedValue,
    }));

    if (error) {
      return <div>Error: {error}</div>;
    }
  };
  const handleInputPhoneChange = (field, value) => {
    console.log(value);
    let processedValue = value;
    if (field === "phone") {
      processedValue = value.replace(/\D/g, '');
      if (value.length < 10 || value.length > 13) {
        setNumValidationPhone(true);
        setNumberValidationErrorMsg("Please Enter number in between 10 to 13 digit");
      }
      else {
        setNumberValidationErrorMsg(" ");
        setNumValidationPhone(false);
      }
    }
    console.log(processedValue);
    setAddData((prevData) => ({
      ...prevData,
      [field]: processedValue,
    }));
    if (field === "phone") {
      setHasChanges(true);

    }
    if (error) {
      return <div>Error: {error}</div>;
    }
  };

  const sendotp = () => {
    sendProfileOtp({ otp_data: profiledatas.email });
  };
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';

  return (
    <>
      <Helmet>
        <style type="text/css">{`
    footer{
    display:none;
   }
    `}</style>
      </Helmet>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Fusion Kitchen | Profile</title>
      </Helmet>
      <section >
        {/* {location.pathname.includes("/profile") ? "true" : "false"} */}
        {width > breakpoint ? (
          <> <div className='userprofilefull'>
            <Container>
              <h5 className='arrow-textprofile'>
                {/* <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} /> */}
                {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-iconprofile' onClick={() => navigate('/myaccount')} alt="arrow-icon" /> */}
                Your Profile
              </h5>
              <Form className='your-profile'>
                <div className='profile-imageupload'>
                  <div className="profile-picture">
                    <span className="profile-initial"> {profiledatas && profiledatas.fname
                      ? profiledatas.fname?.toString()?.charAt(0)
                      : ""}</span>

                  </div>
                </div>
                <Row >
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        label="First Name"
                        fullWidth
                        required
                        type="text"
                        size="small"
                        className={'textinputprofile'}
                        // size="small"
                        placeholder={
                          fNameError ? "First Name-Mandatory" : "First Name"
                        }
                        error={fNameError}
                        disabled={!isEditableFName && !fNameError}
                        value={
                          addData && addData.first_name
                            ? addData.first_name
                            : ""
                        }
                        name="first_name"
                        onChange={(e) =>
                          handleInputAddChange("first_name", e.target.value)
                        }
                        onMouseLeave={() => setIsEditableFName(false)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" >
                              <p className="text-success"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleChangeClick("firstname")}
                              >
                                CHANGE
                              </p>
                            </InputAdornment>
                          ),
                          fontChnage
                        }}
                        inputRef={inputRefFirstName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        required
                        label="Last Name "
                        fullWidth
                        type="text"
                        size="small"
                        className='textinputprofile'
                        error={lNameError}
                        disabled={!isEditableLName && !lNameError}
                        placeholder={
                          lNameError ? "Last Name-Mandatory" : "Last Name"
                        }
                        value={
                          addData && addData.last_name
                            ? addData.last_name
                            : ""
                        }
                        name="last_name"
                        onChange={(e) =>
                          handleInputAddChange("last_name", e.target.value)
                        }
                        onMouseLeave={() => setIsEditableLName(false)}

                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" >
                              <p className="text-success"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleChangeClick("lastname")}
                              >
                                CHANGE
                              </p>
                            </InputAdornment>
                          ),

                        }}
                        inputRef={inputRefLastname}
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        label="Mobile"
                        fullWidth
                        required
                        type="number"
                        className='textinputprofile'
                        size="small"
                        placeholder={
                          phoneError ? "Phone nubmer-Mandatory" : "Phone nubmer"
                        }
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={
                          addData && addData.phone
                            ? addData.phone
                            : ""
                        }
                        name="phone"
                        onChange={(e) => handleInputPhoneChange("phone", e.target.value)}
                        onMouseLeave={() => setIsEditablePhone(false)}
                        error={phoneError || numValidationPhone}
                        helperText={numValidationPhone ? numberValidationErrorMsg : ""}
                        disabled={!isEditablePhone && !phoneError}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <p className="text-success"
                                onClick={() => handleChangeClick("phone")}>
                                CHANGE
                              </p>
                            </InputAdornment>
                          ),

                        }}

                        inputRef={inputRefPhone}

                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        label="Email "
                        fullWidth
                        required
                        className='textinputprofile'
                        size="small"
                        type="email"
                        // placeholder={emailError ? "Email ID-Mandatory" : "Email ID"}
                        value={
                          profiledatas && profiledatas.email
                            ? profiledatas.email
                            : ""
                        }
                        disabled={profiledatas && profiledatas.email ? true : false}
                        readOnly={profiledatas && profiledatas.email ? true : false}
                        name="email"

                      // InputProps={{
                      //   endAdornment: (
                      //     <IconButton size="small" className="custom-clear-icon" >
                      //       <ClearIcon fontSize="small" />
                      //     </IconButton>
                      //   ),
                      // }}
                      />
                    </Form.Group>
                  </Col>
                  {/* date of Birth & gender */}
                  {/* <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                  <Form.Group className="form-group-spacing">
                    <TextField
                      label="DateOfBirth"
                      fullWidth
                      required
                      className='textinputprofile'
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small" className="custom-clear-icon" >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                    />
                  </Form.Group>
                </Col> */}
                  {/* <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                  <Form.Group className="form-group-spacing">
                    <TextField
                      select
                      label="Gender"
                      fullWidth
                      required
                      className='textinputprofile'
                      size="small"
                    >
                      {genderOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} size="small">
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Form.Group>

                </Col> */}
                  {/* end date of Birth & gender */}

                  <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <center>
                      <SaveModal
                        sendotp={sendotp}
                        successshow={successshow}
                        setSuccessshow={setSuccessshow}
                        submitotp={submitotp}
                        addData={addData}
                        profiledatas={profiledatas}
                        hasChanges={hasChanges}
                        failureshow={failureshow}
                        setFailureshow={setFailureshow}
                        saveProfile={saveProfile}
                        profilesuccess={profilesuccess}
                        setProfilesuccess={setProfilesuccess}
                        setFNameError={setFNameError}
                        setLNameError={setLNameError}
                        setPhoneError={setPhoneError}
                        setEmailError={setEmailError}
                        setNumberValidationErrorMsg={setNumberValidationErrorMsg}
                        setNumValidationPhone={setNumValidationPhone}
                      />
                    </center>
                  </Col>
                </Row>
              </Form>

            </Container>
          </div>
            <Container>

            </Container>
          </>) : (<> <div className='userprofilefull'>
            <Container>
              <h5 className='arrow-textprofile'>
                <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
                {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-icon' onClick={() => navigate('/myaccount')} alt="arrow-icon" /> */}
                Your Profile
              </h5>
              <Form className='your-profile'>
                <div className='profile-imageupload'>
                  <div className="profile-picture">
                    <span className="profile-initial"> {profiledatas && profiledatas.fname
                      ? profiledatas.fname?.toString()?.charAt(0)
                      : ""}</span>

                  </div>
                </div>
                <Row >
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        label="First Name"
                        fullWidth
                        required
                        type="text"
                        className='textinputprofile'
                        size="small"
                        placeholder={
                          fNameError ? "First Name-Mandatory" : "First Name"
                        }
                        error={fNameError}
                        disabled={!isEditableFName && !fNameError}
                        value={
                          addData && addData.first_name
                            ? addData.first_name
                            : ""
                        }
                        name="first_name"
                        onChange={(e) =>
                          handleInputAddChange("first_name", e.target.value)
                        }
                        onMouseLeave={() => setIsEditableFName(false)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" >
                              <p className="text-success"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleChangeClick("firstname")}
                              >
                                CHANGE
                              </p>
                            </InputAdornment>
                          ),
                        }}
                        inputRef={inputRefFirstName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        required
                        label="Last Name "
                        fullWidth
                        type="text"
                        className='textinputprofile'
                        size="small"
                        error={lNameError}
                        disabled={!isEditableLName && !lNameError}
                        placeholder={
                          lNameError ? "Last Name-Mandatory" : "Last Name"
                        }
                        value={
                          addData && addData.last_name
                            ? addData.last_name
                            : ""
                        }
                        name="last_name"
                        onChange={(e) =>
                          handleInputAddChange("last_name", e.target.value)
                        }
                        onMouseLeave={() => setIsEditableLName(false)}

                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" >
                              <p className="text-success"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleChangeClick("lastname")}
                              >
                                CHANGE
                              </p>
                            </InputAdornment>
                          ),
                        }}
                        inputRef={inputRefLastname}
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        label="Mobile"
                        fullWidth
                        required
                        type="number"
                        className='textinputprofile'
                        size="small"
                        placeholder={
                          phoneError ? "Phone nubmer-Mandatory" : "Phone nubmer"
                        }
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={
                          addData && addData.phone
                            ? addData.phone
                            : ""
                        }
                        name="phone"
                        onChange={(e) =>
                          handleInputAddChange("phone", e.target.value)
                        }
                        onMouseLeave={() => setIsEditablePhone(false)}
                        error={phoneError || numValidationPhone}
                        helperText={numValidationPhone ? numberValidationErrorMsg : ""}
                        disabled={!isEditablePhone && !phoneError}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" >
                              <p className="text-success"
                                onClick={() => handleChangeClick("phone")}>
                                CHANGE
                              </p>
                            </InputAdornment>
                          ),
                        }}
                        inputRef={inputRefPhone}
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="form-group-spacing">
                      <TextField
                        label="Email "
                        fullWidth
                        required
                        className='textinputprofile'
                        size="small"
                        type="email"
                        // placeholder={emailError ? "Email ID-Mandatory" : "Email ID"}
                        value={
                          profiledatas && profiledatas.email
                            ? profiledatas.email
                            : ""
                        }
                        disabled={profiledatas && profiledatas.email ? true : false}
                        readOnly={profiledatas && profiledatas.email ? true : false}
                        name="email"

                      // InputProps={{
                      //   endAdornment: (
                      //     <IconButton size="small" className="custom-clear-icon" >
                      //       <ClearIcon fontSize="small" />
                      //     </IconButton>
                      //   ),
                      // }}
                      />
                    </Form.Group>
                  </Col>
                  {/* date of Birth & gender */}
                  {/* <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                  <Form.Group className="form-group-spacing">
                    <TextField
                      label="DateOfBirth"
                      fullWidth
                      required
                      className='textinputprofile'
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small" className="custom-clear-icon" >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                    />
                  </Form.Group>
                </Col> */}
                  {/* <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                  <Form.Group className="form-group-spacing">
                    <TextField
                      select
                      label="Gender"
                      fullWidth
                      required
                      className='textinputprofile'
                      size="small"
                    >
                      {genderOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} size="small">
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Form.Group>

                </Col> */}
                  {/* end date of Birth & gender */}

                  <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <center>
                      <SaveModal
                        sendotp={sendotp}
                        successshow={successshow}
                        setSuccessshow={setSuccessshow}
                        submitotp={submitotp}
                        addData={addData}
                        profiledatas={profiledatas}
                        hasChanges={hasChanges}
                        failureshow={failureshow}
                        setFailureshow={setFailureshow}
                        saveProfile={saveProfile}
                        profilesuccess={profilesuccess}
                        setProfilesuccess={setProfilesuccess}
                        setFNameError={setFNameError}
                        setLNameError={setLNameError}
                        setPhoneError={setPhoneError}
                        setEmailError={setEmailError}
                        setNumberValidationErrorMsg={setNumberValidationErrorMsg}
                        setNumValidationPhone={setNumValidationPhone}
                      />
                    </center>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
            <Container>
            </Container>
          </>)}

      </section>
    </>
  );
};

function SaveModal(props) {
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const [saveshow, setSaveshow] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [closesuccesspopup, setClosesuccesspopup] = useState(true);
  const handleClose = () => setShow(false);
  const profileClose = () => props.setProfilesuccess(false);
  const handleShow = () => {
    if (props.addData.first_name === null || props.addData.first_name === "") {
      props.setFNameError(true);
    }
    if (props.addData.last_name === null || props.addData.last_name === "") {
      props.setLNameError(true);
    }
    if (props.addData.phone === null || props.addData.phone === "") {
      props.setPhoneError(true);
    }
    if (props.addData.email === null || props.addData.email === "") {
      props.setEmailError(true);
    }
    if (props.addData.phone.length < 10 || props.addData.phone.length > 13) {
      props.setNumValidationPhone(true);
      props.setNumberValidationErrorMsg("Number should be between 10 and 13 digits");
    } else {
      props.setNumValidationPhone(false);
      props.setNumberValidationErrorMsg("");
    }

    if (
      props.addData.first_name &&
      props.addData.last_name &&
      props.addData.phone &&
      props.addData.email && props.addData.phone.length >= 10 && props.addData.phone.length <= 13
    ) {
      setShow(true);
    }
    console.log(props.addData, props.addData.first_name);
    // setShow(true);
  };
  const handlesuccessclose = () => {
    props.setSuccessshow(false);
    setClosesuccesspopup(false);
    setSaveshow(false);
    setOtp(["", "", "", ""]);
  };
  const handlefailureclose = () => {
    props.setFailureshow(false);
    setOtp(["", "", "", ""]);
  };

  const handleOtpshow = () => {
    if (props.hasChanges) {
      if (props.profiledatas.phone == props.addData.phone) {
        handleClose();
        props.saveProfile(props.addData);
      } else {
        handleClose(); // Close the first modal
        setSaveshow(true); // Show the second modal
        props.sendotp();
      }
    } else {
      handleClose();
      props.saveProfile(props.addData);
    }
  };

  const handleResendOtp = () => {
    setSaveshow(true); // Show the second modal
    props.sendotp();
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    props.submitotp(otp);
    handleOtpclose();
    setOtp(["", "", "", ""]);
  };

  const handleOtpclose = () => setSaveshow(false);

  //mask phone number
  var str = props?.addData?.phone;
  str = str.replace(/(?<=\d\d)\d(?=\d{2})/g, "*");
  const allEmpty = otp.some((digit) => digit === "");

  return (
    <>
      <Button className="Update-profile" onClick={handleShow}>
        Update Profile
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        id="profile-save"
        className={theme === "dark" ? "dark-profile-save" : null}
      >
        <Modal.Body>
          <h3>Save Changes?</h3>
          <p>
            Are you sure want to <br /> save the changes
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel-btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="save-btn" onClick={handleOtpshow}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={props.successshow}
        onHide={handlesuccessclose}
        backdrop="static"
        keyboard={false}
        centered
        className={`otp-screen ${theme === "dark" ? "dark-theme" : ""}`}
      >
        <Modal.Body>
          <>
            <div className="content">
              <div className="lottie-ani">
                <Player autoplay loop src={SuccessLottie}></Player>
              </div>
              <h3 className="done">OTP Verified Successfully</h3>

              <button onClick={handlesuccessclose} className="verify-btn done">
                Done
              </button>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={props.profilesuccess}
        backdrop="static"
        onHide={profileClose}
        keyboard={false}
        centered
        className={`otp-screen ${theme === "dark" ? "dark-theme" : ""}`}
      >
        <Modal.Body>
          <>
            <div className="content">
              <div className="lottie-ani">
                <Player autoplay loop src={SuccessLottie}></Player>
              </div>
              <h3 className="done">Profile Updated Successfully</h3>

              <button onClick={profileClose} className="verify-btn done">
                Done
              </button>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={saveshow}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        className={`otp-screen ${theme === "dark" ? "dark-theme" : ""}`}
      >
        <Modal.Body>
          <>
            <form onSubmit={handleOtpSubmit}>
              <div className="content">
                <div className="lottie-ani">
                  <Player autoplay loop src={KeyLottie}></Player>
                </div>
                <h3>OTP Verification</h3>
                <p>
                  Enter OTP Code send to <span>+{str}</span>
                </p>
                <OTPInput otp={otp} setOtp={setOtp} />
                <span className="invalid-msg"></span>
                <p>Don’t received OTP Code?</p>
                <button className="resend-btn" onClick={handleResendOtp}>
                  Resend Code
                </button>
                <Button
                  className="verify-btn verify"
                  disabled={allEmpty}
                  type="submit"
                >
                  Verify & Proceed
                </Button>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>

      <Modal
        show={props.failureshow}
        centered
        backdrop="static"
        keyboard={false}
        className={`otp-screen ${theme === "dark" ? "dark-theme" : ""}`}
      >
        <Modal.Body>
          <>
            <div className="content">
              <div className="lottie-ani">
                <Player autoplay loop src={FalidLottie}></Player>
              </div>
              <h3 className="retry">OTP Verified Failed</h3>
              <button className="verify-btn retry" onClick={handlefailureclose}>
                Retry
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

//otp input
const OTPInput = ({ otp, setOtp, OTPValid }) => {
  const handleOTPChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOtp(newOTP);
      if (index < 3 && value.length === 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  return (
    <>
      <div className="input-box">
        {otp.map((digit, index) => (
          <input
            key={index}
            value={digit}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            style={{
              textAlign: "center",
            }}
            onChange={(e) => handleOTPChange(e, index)}
            className={OTPValid === false && "error"}
          />
        ))}
      </div>
      {/* <button onClick={handleSubmit}>Submit</button> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile, // Replace 'post' with your reducer name
  userData: state.userdata.userData,
  sendotpprofile: state.sendotpprofile.sendotpprofile,
  saveprofile: state.saveprofile.saveprofile,
});

const mapDispatchToProps = {
  getProfile,
  sendProfileOtp,
  saveProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);









